import { TRACKING_EVENTS } from "@config/tracking";
import TagManager from "react-gtm-module";

export const track = (event: string, dataLayer: Record<string, any>): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: event,
      ...dataLayer,
    },
  });
};

export const trackWizardSubmitted = () => {
  track(TRACKING_EVENTS.wizardSubmitted, {});
};
export const trackSignUpButtonClicked = () => {
  track(TRACKING_EVENTS.sign_up_click_on_sign_up, {});
};
export const trackLoginFromSignUpPageButtonClicked = () => {
  track(TRACKING_EVENTS.sign_up_form_click_on_login, {});
};
export const trackAccountAlreadyExists = () => {
  track(TRACKING_EVENTS.sign_up_error_account_already_exists, {});
};
export const trackLoginFromSignUpPage = () => {
  track(TRACKING_EVENTS.sign_up_form_login_successfully, {});
};
export const trackClickForgotPassword = () => {
  track(TRACKING_EVENTS.login_forgot_password, {});
};
export const trackClickLogin = () => {
  track(TRACKING_EVENTS.login_click_login, {});
};
export const trackLoginSuccessfully = ({
  source,
  customer_email,
}: {
  source: "sign-in" | "sign-up";
  customer_email: string;
}) => {
  track(TRACKING_EVENTS.login_successfully, { source_page: source, customer_email });
};
export const trackLoginErorr = () => {
  track(TRACKING_EVENTS.login_error, {});
};
export const trackGeneralSignUpError = () => {
  track(TRACKING_EVENTS.sign_up_error, {});
};
export const trackSignUp = ({
  userAuthId,
  email,
}: {
  userAuthId: string;
  email: string;
}) => {
  track(TRACKING_EVENTS.SIGN_UP, { userAuthId, customer_email: email });
};
export const trackLogin = () => {
  track(TRACKING_EVENTS.LOGIN, {});
};
export const trackInviteTeamMembersCTAclick = () => {
  track(TRACKING_EVENTS.InviteTeamMembersCTAclick, {});
};
export const trackTeamMembersInvited = () => {
  track(TRACKING_EVENTS.teamMembersInvited, {});
};
export const trackInquiryWizardStarted = () => {
  track(TRACKING_EVENTS.inquiryWizardStarted, {});
};
export const trackInquiryOrderPartsCTAclick = () => {
  track(TRACKING_EVENTS.inquiryWizardStarted, {});
};
export const trackInquiryOrderPartsCancelledStatusCTAclick = () => {
  track(TRACKING_EVENTS.inquiryOrderPartsCancelledStatusCTAclick, {});
};
export const trackInquiryReorderPartsCTAclick = () => {
  track(TRACKING_EVENTS.inquiryReorderPartsCTAclick, {});
};
export const trackInquiryPartDrawingOpened = () => {
  track(TRACKING_EVENTS.inquiryPartDrawingOpened, {});
};
export const trackInquiryDrawingsDownloaded = () => {
  track(TRACKING_EVENTS.inquiryDrawingsDownloaded, {});
};
export const trackInquiryAcceptFrameworkAgreementCTAclick = () => {
  track(TRACKING_EVENTS.inquiryAcceptFrameworkAgreementCTAclick, {});
};
export const trackInquiryOrderPartsFrameworkAgreementCTAclick = () => {
  track(TRACKING_EVENTS.inquiryOrderPartsFrameworkAgreementCTAclick, {});
};
export const trackOrderReorderPartsCTAclick = () => {
  track(TRACKING_EVENTS.orderReorderPartsCTAclick, {});
};
export const trackOrderPriceBreakdownExpandedLinkClick = () => {
  track(TRACKING_EVENTS.orderPriceBreakdownExpandedLinkClick, {});
};
export const trackOrderPartDrawingOpened = () => {
  track(TRACKING_EVENTS.orderPartDrawingOpened, {});
};
export const trackOrderDeliveriesPartsExpandedArrowclick = () => {
  track(TRACKING_EVENTS.orderDeliveriesPartsExpandedArrowclick, {});
};
export const trackOrderUpdateContactsCTAclick = () => {
  track(TRACKING_EVENTS.orderUpdateContactsCTAclick, {});
};
export const trackFrameworkOrderNextBatchCTAclick = () => {
  track(TRACKING_EVENTS.orderUpdateContactsCTAclick, {});
};
export const trackFrameworkPriceBreakdownExpandedLinkClick = () => {
  track(TRACKING_EVENTS.frameworkPriceBreakdownExpandedLinkClick, {});
};
export const trackFrameworkBatchOrderPartsExpandedArrow = () => {
  track(TRACKING_EVENTS.frameworkBatchOrderPartsExpandedArrow, {});
};
export const trackFrameworkPartDrawingOpened = () => {
  track(TRACKING_EVENTS.frameworkPartDrawingOpened, {});
};
export const trackPlaceOrder = ({
  orderId,
  userId,
  email,
}: {
  orderId: string;
  userId: string;
  email: string;
}) => {
  track(TRACKING_EVENTS.ordered, {
    order_id: orderId,
    user_id: userId,
    customer_email: email,
  });
};
export const trackStartOrderProccess = ({ userId }: { userId: string }) => {
  track(TRACKING_EVENTS.startOrderProccess, {
    user_id: userId,
  });
};
